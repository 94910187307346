:root {
  --ion-color-primary: #014388;
  --ion-color-primary-rgb: 1, 67, 136;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #013b78;
  --ion-color-primary-tint: #1a5694;

  --ion-color-secondary: #a78b72;
  --ion-color-secondary-rgb: 167, 139, 114;
  --ion-color-secondary-contrast: #000000;
  --ion-color-secondary-contrast-rgb: 0, 0, 0;
  --ion-color-secondary-shade: #937a64;
  --ion-color-secondary-tint: #b09780;

  --ion-color-tertiary: #5d5d5e;
  --ion-color-tertiary-rgb: 93, 93, 94;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #525253;
  --ion-color-tertiary-tint: #6d6d6e;

  --ion-color-success: #64a844;
  --ion-color-success-rgb: 100, 168, 68;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #58943c;
  --ion-color-success-tint: #74b157;

  --ion-color-warning: #fccd22;
  --ion-color-warning-rgb: 252, 205, 34;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #deb41e;
  --ion-color-warning-tint: #fcd238;

  --ion-color-danger: #db504a;
  --ion-color-danger-rgb: 219, 80, 74;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #c14641;
  --ion-color-danger-tint: #df625c;

  --ion-color-dark: #070600;
  --ion-color-dark-rgb: 7, 6, 0;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #060500;
  --ion-color-dark-tint: #201f1a;

  --ion-color-medium: #edeff3;
  --ion-color-medium-rgb: 237, 239, 243;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0, 0, 0;
  --ion-color-medium-shade: #d1d2d6;
  --ion-color-medium-tint: #eff1f4;

  --ion-color-light: #ffffff;
  --ion-color-light-rgb: 255, 255, 255;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #e0e0e0;
  --ion-color-light-tint: #ffffff;

  --ion-color-debug: #b800a2;
}
